import promotApi from "@/api/common/promotionNotification";
import commonAct from "@/commonActionHandle.js";
import SkeletonBox from "@/components/SkeletonBox";
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import { debounce } from "vue-debounce";
import { mapState } from "vuex";
export default {
    extends: baseComponent,
    data() {
        let validateCpCount = (rule, value, callback) => {
            var val = parseInt(value);
            if (val < 1) {
                callback(new Error("Are you doing this for fun? min is 1"));
            }
            callback();
        };
        return {
            generateCouponForm: {
                userAssign: "",
                campaignCode: "",
                totalCoupon: 0,
                allowedBooting: false,
            },
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {},
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 21,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "CREATED_AT",
                    directionSort: "desc",
                },
            },
            rules: {
                totalCoupon: [
                    {
                        required: true,
                        message: "Do you miss something?",
                        trigger: "blur",
                    },
                    { validator: validateCpCount, trigger: "blur" },
                ],
                userAssign: [
                    {
                        required: true,
                        message: "Do you miss something?",
                        trigger: "blur",
                    },
                ],
            },
            debounceFnc: null,
            debounceFilterFnc: null,
            toogleFilter: false,
            curentCouponSelected: null,
        };
    },
    props: {
        currentCampain: {
            type: Object,
            default: {},
        },
    },
    components: {
        comments: lazyLoadComponent({
            componentFactory: () => import("@/components/Comments"),
            loading: SkeletonBox,
        }),
    },
    created() {
        this.getElementsList(0);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    watch: {
        currentCampain: function (val) {
            if (val != null) {
                this.getElementsList(0);
                this.generateCouponForm = {};
            }
        },
    },
    computed: {
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 21,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "CREATED_AT",
                directionSort: "desc",
            };
        },
        requestParam() {
            return {
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                pagingItem: Object.assign(
                    this.defaultPagingItem,
                    this.elements.pagingItem
                ),
                filterItem: Object.assign(this.elements.filterItem),
            };
        },
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            staffsInfo: (state) => state.staffs.filter(x => x.mainDepartment === 'OPERATING_STAFF'),
            lowBalanceUser: (state) => state.lowBalanceUser,
        }),
        rollDisable() {
            return !(this.rolesInfo.includes('QA') || this.rolesInfo.includes('OPERATOR_MANAGER '));
        },
    },
    methods: {
        submitForm(formName) {
            this.generateCouponForm.totalCoupon = parseInt(
                this.generateCouponForm.totalCoupon
            );
            this.generateCouponForm.campaignCode = this.currentCampain.campaignCode;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    promotApi
                        .generateCoupon(this.generateCouponForm)
                        .then((res) => {
                            if (res.data.result == 0) {
                                this.$emit("generateCodeSuccess", res.data.data);
                                this.getElementsList(this.elements.pagingItem.pageIndex);
                                this.showSuccessToast(`<div class="text-left">Succees</div>`);
                            } else {
                                commonAct.showError(res.data.message);
                            }
                        })
                        .catch((err) => {
                            commonAct.showError(err.data.message);
                        });
                }
            });
        },
        getElementsList(pageNumber) {
            this.showLoading();
            promotApi
                .getElementsListCoupon(
                    this.currentCampain.campaignCode,
                    pageNumber,
                    this.requestParam
                )
                .then((response) => {
                    if (
                        response.data &&
                        response.data.result === 0 &&
                        response.data.data !== null
                    ) {
                        this.elements.data = response.data.data.data;
                        this.elements.pagingItem = response.data.data.pagingItem;
                        this.hideLoading();
                    } else {
                        this.elements.data = [];
                        this.elements.pagingItem = this.defaultPagingItem;
                        this.hideLoading();
                        if (
                            response.data.message !== null &&
                            response.data.message !== ""
                        ) {
                            commonAct.showError(
                                response.data.message || this.$lang.common.error
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    commonAct.showError(error);
                });
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                default:
                    this.getElementsList(pageNumber);
                    break;
            }
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        sortClick(sortItem) {
            if (this.elements.pagingItem.directionSort == "desc") {
                this.elements.pagingItem.directionSort = "";
            } else {
                this.elements.pagingItem.directionSort = "desc";
            }
            this.elements.pagingItem.orderBy = sortItem;

            switch (this.elements.queryAction) {
                case "search":
                    this.getSearchQueryResult(1);
                    break;
                case "filter":
                    this.getFilterQueryResult(1);
                    break;
                default:
                    this.getElementsList(1);
                    break;
            }
        },
        OnFilter(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.elements.querySearch = "";
            this.getElementsList(pageNumber);
        },
        updateNote() {
            this.showLoading();
            promotApi
                .updateNote(this.curentCouponSelected)
                .then((response) => {

                    if (response.data && response.data.result === 0) {
                        var rootUpdateItemIndex = this.elements.data.findIndex(
                            (x) => x.id == this.curentCouponSelected.id
                        );
                        if (rootUpdateItemIndex >= 0) {
                            this.elements.data[rootUpdateItemIndex] = this.curentCouponSelected;
                        }

                        this.hideLoading();
                        this.closeCouponNoteEdit();
                    } else {
                        this.hideLoading();
                        if (
                            response.data.message !== null &&
                            response.data.message !== ""
                        ) {
                            commonAct.showError(
                                response.data.message || this.$lang.common.error
                            );
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    commonAct.showError(error);
                });
        },
        updateAllowedBooting(itemTarget) {
            commonAct.showConfirm(
                    `Change state of allowed booting for this coupon ?`,
                    () => {                        
                        this.showLoading();
                        promotApi
                            .updateAllowedBooting({ couponCode :  itemTarget.couponCode, allowedBooting : itemTarget.allowedBooting })
                            .then((response) => {

                                if (response.data && response.data.result === 0) {
                                    this.showSuccessToast("Perform action successed");
                                    this.hideLoading();
                                } else {
                                    itemTarget.allowedBooting = !itemTarget.allowedBooting;
                                    this.hideLoading();
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                itemTarget.allowedBooting = !itemTarget.allowedBooting;
                                console.error(error);
                                this.elements.data = [];
                                this.elements.pagingItem = this.defaultPagingItem;
                                this.hideLoading();
                                commonAct.showError(error);
                            });
                    }
                );
        },
        closeCouponNoteEdit() {
            $("#couponNoteEditor").modal("hide");
            this.curentCouponSelected = null;
        },
        OpenCouponNoteEditor(item) {
            this.curentCouponSelected = Object.assign({}, item);
            this.$nextTick(() => {
                $("#couponNoteEditor").modal({ backdrop: "static", keyboard: true });
            });
        },
    },
};
